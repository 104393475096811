@import "~styles/includes/index.scss";
$top-nav-height: 56px;
$bottom-nav-height: 64px;
$notification-bar-height: 40px;

.messageBar {
	margin-top: $top-nav-height + $bottom-nav-height;
	background: #f7a600;
	padding: 12px 0;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 400;
	cursor: auto;

	a {
		font-weight: 400;

		&:hover {
			color: red;
		}
	}

	@media (max-width: $breakpoint-medium) {
		margin-top: 117px;

		&.noSearchbar {
			margin-top: $top-nav-height;
		}
	}

	.icon {
		@media (max-width: $breakpoint-medium) {
			margin-left: 16px;
		}
	}
	.messageText {
		margin: 0 8px;
		text-align: left;
		font-weight: 400;
		color: $color-primary-blue;

		button {
			@include button-reset();

			&:hover {
				text-decoration: underline;
			}
		}
	}
}

.messageBarShiftUp {
	margin-top: $top-nav-height;
}

.hasLink {
	cursor: pointer;
}
