@use "sass:math";

@mixin button-reset() {
	font-size: 16px;
	background: transparent;
	padding: 0;
	margin: 0;
	border: 0;
	cursor: pointer;
}

@mixin truncate-text($max-height, $amount-of-lines) {
	max-height: #{$max-height}px;
	line-height: #{math.div($max-height, $amount-of-lines)}px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: $amount-of-lines;
	-webkit-box-orient: vertical;
}

@mixin focus-reset() {
	&:focus {
		outline: none;
		box-shadow: none;
		border: 0;
	}

	&:focus-visible {
		outline: -webkit-focus-ring-color auto 1px;
		outline-offset: 2px;
	}

	&:-moz-focusring {
		outline: auto;
	}
}

@mixin text-field-style() {
	max-width: none;

	input,
	textarea {
		font-size: 16px;
		font-weight: 300;
		line-height: 160%;
		border-radius: 2px;
		border: $global-border-dark-style;
		box-shadow: none;
		background-color: white;
		background-image: none;
		color: $font-color-main;
		transition: none;

		&::placeholder {
			color: $font-color-placeholder;
		}

		&:not(:disabled) {
			&:hover {
				box-shadow: none;
				border-color: darken($global-border-dark-color, 10%);
			}
			&:focus {
				background-image: none;
				box-shadow: none;
				border-color: $color-secondary-blue;
				box-shadow: inset 0px 0px 0px 1px $color-secondary-blue;
				transition: none;
				background-color: $color-tint-blue-99;
			}
		}
		&:disabled {
			background-color: $color-tint-grey-96;
			box-shadow: none;
			color: $font-color-faded;
		}
	}

	input,
	textarea {
		@media (max-width: $breakpoint-small-medium-max) {
			padding: 12px 16px;
			height: auto;
		}
	}

	input.error,
	textarea.error {
		border-color: $color-info-danger;
		background-image: none;
		background-color: lighten($color-info-danger, 40%);
		&:focus {
			background-image: none;
		}
		&:hover:not(:focus) {
			border-color: $color-info-danger-darkest;
		}
	}
}

@mixin blockWrapper($padding: 24px) {
	background: white;
	border: $global-border-style;
	box-shadow: $global-shadow-style;
	padding: $padding;
	border-radius: $global-border-radius;
}

@mixin paddingNone {
	padding: 0px;
}

@mixin paddingSmall {
	padding: 72px 0px;

	@media (max-width: $breakpoint-small-medium) {
		padding: 32px 0px;
	}
}

@mixin paddingMedium {
	padding: 96px 0px;

	@media (max-width: $breakpoint-small-medium) {
		padding: 40px 0px;
	}
}

@mixin paddingLarge {
	padding: 120px 0px;

	@media (max-width: $breakpoint-small-medium) {
		padding: 48px 0px;
	}
}

@mixin backgroundWhite {
	background-color: white;
}

@mixin backgroundGrey {
	background-color: $color-tint-grey-80;
}

@mixin backgroundPurple {
	background-color: $color-primary-blue;
}

@mixin focus-outline($onDark: false) {
	$focus-border-color: $color-primary-blue;

	@if $onDark {
		$focus-border-color: #fff;
	}

	outline: 2px solid $focus-border-color;
	outline-offset: 2px;

	@supports (outline-color: invert) {
		outline-color: invert;
	}
}
